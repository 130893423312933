import Vue from 'vue';
import MainLayout from '@/layouts/MainLayout';
import { getAll } from '@/services/subscriptions';
// eslint-disable-next-line no-unused-vars
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import AppConfig from '@/config/app';
import { MODULE_NAME as AUTH_MODULE, GETTERS as AUTH_GETTERS } from '@/store/modules/auth/types';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Subscriptions',

  components: { MainLayout },

  data() {
    return {
      premiumPlanName: 'main-premium-product',
      currentPlan: null,
      paddleInstance: null,
    };
  },

  computed: {
    ...mapGetters(AUTH_MODULE, {
      loggedUser: AUTH_GETTERS.USER,
    }),

    parsePremiumPlanName() {
      if (this.premiumPlanName == 'main-premium-product') {
        return 'Premium Lifetime';
      }

      return 'N/A';
    },
  },

  async mounted() {
    this.initPaddleJsScript();

    this.getAllSubscriptions();
  },

  methods: {
    async initPaddleJsScript() {
      const environmentToUse = AppConfig.PADDLE_USE_SANDBOX == true ? 'sandbox' : 'production';

      const paddle = await initializePaddle({
        environment: environmentToUse,
        token: AppConfig.PADDLE_CLIENT_SIDE_TOKEN,
      });

      if (!paddle) {
        return;
      }

      this.paddleInstance = paddle;
    },

    async getAllSubscriptions() {
      const result = await getAll();

      result.forEach((element) => {
        if (element.package_name == this.premiumPlanName) {
          this.currentPlan = this.premiumPlanName;
        }
      });
    },

    onClickPayNow() {
      /** @type {Paddle} */
      const paddleInstance = this.paddleInstance;
      const loggedUserId = this.loggedUser.userId;
      const customerEmail = this.loggedUser.email;

      paddleInstance.Spinner.show();

      paddleInstance.Checkout.open({
        settings: {
          displayMode: 'overlay',
          theme: 'light',
          locale: 'en',
          successUrl: window.location.href,
        },

        customer: {
          email: customerEmail,
        },

        customData: {
          userId: loggedUserId,
        },

        items: [{ priceId: AppConfig.PADDLE_PREMIUM_PRICE_ID, quantity: 1 }],
      });
    },
  },
});
