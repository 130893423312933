import Api from '@/utils/api';
import store from '@/store';

export const getAll = async () => {
  try {
    const bearerToken = { Authorization: `Bearer ` + store.getters['auth/USER'].token };

    const response = await Api.get(`/subscriptions`, { headers: bearerToken });

    return response.data;
  } catch (error) {
    console.log(`Api error when calling /subscriptions`);
  }

  return null;
};
